function showOrHide(objDOM, id, gomb) {
    var obj = document.getElementById(id);

    if (obj.style.display === 'block') {
        obj.style.display = 'none';
        objDOM.innerHTML = this.tmp;
    } else {
        obj.style.display = 'block';
        this.tmp = objDOM.innerHTML;
        objDOM.innerHTML = gomb;
    }
}

function checkJelszo(input, objDOM) {
    if (input.value !== objDOM.value) {
        input.setCustomValidity('Nem egyezik a két jelszó!');
    } else {
        input.setCustomValidity('');
    }
}

function jelszoErosseg(jelszo, elementId) {
    var desc = [];

    desc[0] = ['Nagyon gyenge', '#ff0000'];
    desc[1] = ['Gyenge', '#cc0000'];
    desc[2] = ['Jobb', '#ff6c00'];
    desc[3] = ['Közepes', '#ffd200'];
    desc[4] = ['Jó', '#4cbe0d'];
    desc[5] = ['Erős', '#398b0c'];
    desc[6] = ['Nagyon erős', '#235508'];

    var pont = 0;

    if (jelszo.length > 7) pont++;
    if (jelszo.match(/[a-z]/)) pont++;
    if (jelszo.match(/[A-Z]/)) pont++;
    if (jelszo.match(/\d+/)) pont++;
    if (jelszo.match(/.[!,@,#,$,%,^,&,*,?,_,~,-,(,)]/)) pont++;
    if (jelszo.length > 11) pont++;

    var elem = document.getElementById(elementId);
    elem.innerHTML = desc[pont][0];

    if (pont > 0) {
        elem.style.width = pont * 50 + 'px';
    } else {
        elem.style.width = '';
    }
    elem.style.color = desc[pont][1];

    elem.style.borderBottomWidth = '5px';
    elem.style.marginTop = '-5px';
    elem.style.borderColor = desc[pont][1];
}

window.onscroll = function () {
    var fejlec = document.getElementById('fejlec');

    if (window.pageYOffset > 0) {
        fejlec.style.backgroundColor = 'rgba(190, 190, 190, 0.3)';
        fejlec.style.boxShadow = '0px 0px 3px 1px rgba(0, 0, 0, 0.5)';
    } else {
        fejlec.style.backgroundColor = 'rgb(225, 225, 225)';
        fejlec.style.boxShadow = '';
    }
};

function onKeyPressTextArea(e) {
    var event = e || window.event;

    var ertek = this.value;

    var pos = this.selectionStart;

    var str = ertek.substring(0, pos);

    var charCode = event.charCode || event.which;

    var chr = String.fromCharCode(charCode);

    switch (chr) {
        case '"':
            if (str.lastIndexOf('„') > str.lastIndexOf('”')) {
                this.value = ertek.beszur('”', pos);
            } else {
                this.value = ertek.beszur('„', pos);
            }

            this.focus();
            this.setSelectionRange(pos + 1, pos + 1);

            break;

        case "'":
            this.value = ertek.beszur('’', pos);

            this.focus();
            this.setSelectionRange(pos + 1, pos + 1);

            break;

        default:
            var evt = document.createEvent('UIEvents');

            evt.initUIEvent('keypress', true, true, window, 1);

            event.dispatchEvent(evt);

            break;
    }

    return false;
}
window.onload = function () {
    var textareas = document.getElementsByTagName('textarea');

    var i;

    for (i = 0; i < textareas.length; i++) {
        textareas[i].onkeypress = onKeyPressTextArea;
    }

    var atags = document.getElementsByTagName('a');

    for (i = 0; i < atags.length; i++) {
        if (atags[i].title !== '') {
            atags[i].setAttribute('desc', atags[i].title);
            atags[i].title = '';
        }
    }
};

function allCheckbox(obj) {
    var pipalva = false;

    if (obj.checked) {
        pipalva = true;
    }

    var inputs = document.getElementsByTagName('input');
    var i = 0;
    while (i < inputs.length) {
        if (inputs[i].type === 'checkbox') inputs[i].checked = pipalva;
        i++;
    }
}
